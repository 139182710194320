import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BroadcastService, EVENTS } from './broadcast.service';
import {
  TradeValueRequestAnswer,
  autocomplete,
  Lead,
  Option,
  unhaggleRequest,
  verifyPin,
  PinVerifyResponse,
  ConditionQuestionAnswer,
  TradeValueRequestType,
} from 'src/types/vehicle';
import { ApiError } from 'src/types/errors';
import { DGLDealerItem } from 'src/types/gdl-dealers';
import { GuaranteedValue } from 'src/types/guaranteed-value';
import { Utils } from '../utils';
import { ConditionQuestionsResponse } from 'src/types/condition-question';
import { VehicleTrims, VehicleTrimsResponse } from 'src/types/VehicleTrims';
import { ColorItem } from 'src/types/ColorItem';
import { DrillDownYear } from 'src/types/DrillDown';
import { TraderLead } from 'src/types/TraderLead';
import { TraderTradeValue } from 'src/types/TraderTradeValue';
import { EstimatedTradeValue } from 'src/types/EstimatedTradeValue';

@Injectable({
  providedIn: 'root',
})
export class UsedCarsService {
  private _baseURL: string = environment.trademaxapiUrl;
  private _fuel_api: string = environment.imageUrl;
  public errorReportingService: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService
  ) {}

  autocomplete(search: string): Observable<Array<autocomplete>> {
    const _url = `${this._baseURL}used-cars/autocomplete`;
    const query = Utils.kvpStripNulls({
      search_text: search,
    });
    return this.http.get<Array<autocomplete>>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        if (err.status) {
          this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        }
        return of([]);
      })
    );
  }

  is_valid_vin(vin: string): Observable<{ is_valid: boolean }> {
    const _url = `${this._baseURL}used-cars/validate/${vin}`;
    const query = Utils.kvpStripNulls({});
    return this.http.get<any>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })
    );
  }

  get_plate_to_vin_trims(plate: string, state: string): Observable<VehicleTrims | null> {
    const _url = `${this._baseURL}used-cars/plate-to-vin/${plate}/${state}`;
    const query = Utils.kvpStripNulls({});
    return this.http.get<VehicleTrimsResponse>(`${_url}`, { params: query }).pipe(
      map((response: VehicleTrimsResponse) => {
        return <VehicleTrims>response.Data;
      }),
      catchError((err: HttpErrorResponse) => {
        //We dont want to broadcast the error, it will trigger an annoying popup
        if (err.status != 400) this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return throwError(<ApiError>err.error);
      })
    );
  }

  get_trims(vin: string, value_type: TradeValueRequestType): Observable<VehicleTrims | null> {
    const _url = `${this._baseURL}used-cars/get-trims/${vin}`;
    const query = Utils.kvpStripNulls({
      value_type: value_type,
    });

    return this.http.get<any>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return <VehicleTrims>response.Data;
      })
    );
  }

  is_valuation_found(vin: string, uvc: string): Observable<{ is_valuation_found: boolean } | null> {
    const _url = `${this._baseURL}used-cars/is-valuation-found/${uvc}`;
    const query = Utils.kvpStripNulls({
      vin: vin || null,
    });
    return this.http.get<any>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return { is_valuation_found: response.Data };
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })
    );
  }

  get_options(vin: string | null = null, uvc: string): Promise<Array<Option> | null> {
    const _url = `${this._baseURL}used-cars/get-options/${uvc}`;
    const query = Utils.kvpStripNulls({
      vin: vin,
    });

    return this.http
      .get<Array<Option>>(`${_url}`, { params: query })
      .pipe(
        map((response: any) => {
          const { options } = response.Data;
          return <Array<Option>>options;
        })
      )
      .toPromise();
  }

  get_questions(
    vin: string,
    uvc: string,
    kilometers_mileage: string | number,
    gdl_dealer_id: string | null = null,
    adcodes: string | null = null,
    language: string | null = null
  ): Promise<ConditionQuestionsResponse> {
    const _url = `${this._baseURL}trade-value/questions/${vin || null}/${uvc}`;
    const query = Utils.kvpStripNulls({
      kilometers_mileage: kilometers_mileage,
      gdl_dealer_id: gdl_dealer_id,
      adcodes: adcodes,
      language: language,
    });

    return this.http
      .get<any>(`${_url}`, { params: query })
      .pipe(
        map((response: any) => {
          return response.Data;
        })
      )
      .toPromise();
  }

  get_trader_trade_value(
    value_type: TradeValueRequestType,
    vin: string,
    uvc: string,
    kilometers_mileage: string,
    state_province: string,
    adcodes: string | null = null,
    lead: TraderLead,
    revise_lead_id: string | null = null,
    provider_program_id: string | null = null
  ): Observable<TraderTradeValue> {
    const _url = `${this._baseURL}trade-value/get-trader-tradevalue`;
    const query = Utils.kvpStripNulls({
      value_type: value_type,
      vin: vin,
      uvc: uvc,
      kilometers_mileage: kilometers_mileage,
      state_province: state_province,
      adcodes: adcodes,
      revise_lead_id: revise_lead_id,
      provider_program_id: provider_program_id,
    });

    const lead_body = {
      ...lead,
      answers: {
        answers: lead.answers.answers.map((answer) => {
          return {
            questionID: answer.questionID,
            answerText: answer.answerCode,
          };
        }),
      },
    };

    return this.http.post<any>(`${_url}`, lead_body, { params: query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return throwError(err);
      })
    );
  }

  get_guaranteed_trade_value(
    vin: string,
    uvc: string,
    kilometers_mileage: string,
    state_province: string,
    adcodes: string | null = null,
    answers: Array<ConditionQuestionAnswer>,
    lead: Lead | null,
    revise_lead_id: string | null = null,
    provider_program_id: string | null = null
  ): Observable<GuaranteedValue> {
    const _url = `${this._baseURL}trade-value/get-gtv/${vin}/${uvc}`;
    const query = Utils.kvpStripNulls({
      kilometers_mileage: kilometers_mileage,
      state_province: state_province,
      adcodes: adcodes,
      gdl_dealer_id: lead?.gdl_dealer_id,
      revise_lead_id: revise_lead_id,
      provider_program_id: provider_program_id,
    });

    const tradevalueAnswers: TradeValueRequestAnswer[] = answers.map((answer) => {
      return {
        questionID: answer.questionID,
        answerText: answer.answerCode,
      };
    });

    const post_data: any = {
      answers: { answers: tradevalueAnswers },
      consumer: lead?.consumer,
      contact: lead?.contact,
      next_vehicle: lead?.next_vehicle?.year != null ? lead?.next_vehicle : null,
    };
    return this.http.post<any>(`${_url}`, post_data, { params: query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })
    );
  }

  get_estimated_trade_value(
    vin: string,
    uvc: string,
    kilometers_mileage: string,
    state_province: string,
    adcodes: string | null = null,
    lead: Lead,
    provider_program_id: string | null = null
  ): Observable<EstimatedTradeValue> {
    const _url = `${this._baseURL}trade-value/get-estimated-tradevalue/${uvc}`;
    const query = Utils.kvpStripNulls({
      vin: vin,
      kilometers_mileage: kilometers_mileage,
      state_province: state_province,
      adcodes: adcodes,
      gdl_dealer_id: lead.gdl_dealer_id,
      provider_program_id: provider_program_id,
    });
    const post_data: any = {
      consumer: lead.consumer,
      contact: lead.contact,
      next_vehicle: lead.next_vehicle?.year != null ? lead.next_vehicle : null,
    };

    return this.http.post<EstimatedTradeValue>(`${_url}`, post_data, { params: query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })
    );
  }

  /*get_estimated_trade_value_nolead(
    vin: string,
    uvc: string,
    kilometers_mileage: string,
    state_province: string,
    adcodes: string | null = null,
    provider_program_id: string | null = null
  ): Observable<TradeValue> {
    const _url = `${this._baseURL}trade-value/get-estimated-tradevalue_nolead/${uvc}`;
    const _query = Utils.kvpStripNulls({
      vin: vin,
      kilometers_mileage: kilometers_mileage,
      state_province: state_province,
      adcodes: adcodes,
      provider_program_id: provider_program_id,
    });
    return this.http.post<TradeValue>(`${_url}`, null, { params: _query }).pipe(
      map((response: any) => {
        return response.Data;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })
    );
  }*/

  get_drilldown_used(
    year: string | null = null,
    make: string | null = null,
    model: string | null = null
  ): Observable<DrillDownYear[]> {
    const _url = `${this._baseURL}used-cars/drilldown`;
    const query = Utils.kvpStripNulls({
      year: year,
      make: make,
      model: model,
    });

    return this.http.get<number>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return response.Data.drilldown.class_list[0].year_list;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return throwError(err);
      })
    );
  }

  get_drilldown_new(
    year: string | null = null,
    make: string | null = null,
    model: string | null = null
  ): Observable<DrillDownYear[]> {
    const _url = `${this._baseURL}new-cars/drilldown`;
    const query = Utils.kvpStripNulls({
      year: year,
      make: make,
      model: model,
    });

    return this.http.get<number>(`${_url}`, { params: query }).pipe(
      map((response: any) => {
        return response.Data.drilldown.class_list[0].year_list;
      }),
      catchError((err) => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return throwError(err);
      })
    );
  }

  unhaggle_lead_processing(unhaggleLead: unhaggleRequest): Observable<any> {
    const _url = `${this._baseURL}unhaggle/phone_validation`;
    return this.http.post(`${_url}`, unhaggleLead).pipe(
      map((response: any) => {
        return response.Data;
      }) /*, 
      catchError(err => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })*/
    );
  }

  unhaggle_pin_verify(pin: verifyPin): Observable<PinVerifyResponse> {
    const _url = `${this._baseURL}unhaggle/phone_validation/verify_pin`;
    return this.http.post(`${_url}`, pin).pipe(
      map((response: any) => {
        return response.Data;
      }) /*, 
      catchError(err => {
        this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })*/
    );
  }

  static postalCodeToProvince(postalCode: string, type = 'code'): string {
    const provinces = {
      A: { code: 'NL', name: 'Newfoundland and Labrador' },
      B: { code: 'NS', name: 'Nova Scotia' },
      C: { code: 'PE', name: 'Prince Edward Island' },
      E: { code: 'NB', name: 'New Brunswick' },
      G: { code: 'QC', name: 'Quebec' },
      H: { code: 'QC', name: 'Quebec' },
      J: { code: 'QC', name: 'Quebec' },
      K: { code: 'ON', name: 'Ontario' },
      L: { code: 'ON', name: 'Ontario' },
      M: { code: 'ON', name: 'Ontario' },
      N: { code: 'ON', name: 'Ontario' },
      P: { code: 'ON', name: 'Ontario' },
      R: { code: 'MB', name: 'Manitoba' },
      S: { code: 'SK', name: 'Saskatchewan' },
      T: { code: 'AB', name: 'Alberta' },
      V: { code: 'BC', name: 'British Columbia' },
      X: { code: 'NT', name: 'Northwest Territories' },
      Y: { code: 'YT', name: 'Yukon' },
    };

    return provinces[postalCode.charAt(0).toUpperCase()]?.[type];
  }

  static zipCodeToState(zipCode: number, type = 'code'): string {
    const state = { code: '', name: '' };
    if (zipCode >= 99501 && zipCode <= 99950) {
      state.code = 'AK';
      state.name = 'Alaska';
    } else if (zipCode >= 35004 && zipCode <= 36925) {
      state.code = 'AL';
      state.name = 'Alabama';
    } else if (zipCode >= 71601 && zipCode <= 72959) {
      state.code = 'AR';
      state.name = 'Arkansas';
    } else if (zipCode >= 75502 && zipCode <= 75502) {
      state.code = 'AR';
      state.name = 'Arkansas (Texarkana)';
    } else if (zipCode >= 85001 && zipCode <= 86556) {
      state.code = 'AZ';
      state.name = 'Arizona';
    } else if (zipCode >= 90001 && zipCode <= 96162) {
      state.code = 'CA';
      state.name = 'California';
    } else if (zipCode >= 80001 && zipCode <= 81658) {
      state.code = 'CO';
      state.name = 'Colorado';
    } else if (zipCode >= 6001 && zipCode <= 6389) {
      state.code = 'CT';
      state.name = 'Connecticut';
    } else if (zipCode >= 6401 && zipCode <= 6928) {
      state.code = 'CT';
      state.name = 'Connecticut';
    } else if (zipCode >= 20001 && zipCode <= 20039) {
      state.code = 'DC';
      state.name = 'Dist of Columbia';
    } else if (zipCode >= 20042 && zipCode <= 20599) {
      state.code = 'DC';
      state.name = 'Dist of Columbia';
    } else if (zipCode >= 20799 && zipCode <= 20799) {
      state.code = 'DC';
      state.name = 'Dist of Columbia';
    } else if (zipCode >= 19701 && zipCode <= 19980) {
      state.code = 'DE';
      state.name = 'Delaware';
    } else if (zipCode >= 32004 && zipCode <= 34997) {
      state.code = 'FL';
      state.name = 'Florida';
    } else if (zipCode >= 30001 && zipCode <= 31999) {
      state.code = 'GA';
      state.name = 'Georgia';
    } else if (zipCode >= 39901 && zipCode <= 39901) {
      state.code = 'GA';
      state.name = 'Georga (Atlanta)';
    } else if (zipCode >= 96701 && zipCode <= 96898) {
      state.code = 'HI';
      state.name = 'Hawaii';
    } else if (zipCode >= 50001 && zipCode <= 52809) {
      state.code = 'IA';
      state.name = 'Iowa';
    } else if (zipCode >= 68119 && zipCode <= 68120) {
      state.code = 'IA';
      state.name = 'Iowa (OMAHA)';
    } else if (zipCode >= 83201 && zipCode <= 83876) {
      state.code = 'ID';
      state.name = 'Idaho';
    } else if (zipCode >= 60001 && zipCode <= 62999) {
      state.code = 'IL';
      state.name = 'Illinois';
    } else if (zipCode >= 46001 && zipCode <= 47997) {
      state.code = 'IN';
      state.name = 'Indiana';
    } else if (zipCode >= 66002 && zipCode <= 67954) {
      state.code = 'KS';
      state.name = 'Kansas';
    } else if (zipCode >= 40003 && zipCode <= 42788) {
      state.code = 'KY';
      state.name = 'Kentucky';
    } else if (zipCode >= 70001 && zipCode <= 71232) {
      state.code = 'LA';
      state.name = 'Louisiana';
    } else if (zipCode >= 71234 && zipCode <= 71497) {
      state.code = 'LA';
      state.name = 'Louisiana';
    } else if (zipCode >= 1001 && zipCode <= 2791) {
      state.code = 'MA';
      state.name = 'Massachusetts';
    } else if (zipCode >= 5501 && zipCode <= 5544) {
      state.code = 'MA';
      state.name = 'Massachusetts (Andover)';
    } else if (zipCode >= 20331 && zipCode <= 20331) {
      state.code = 'MD';
      state.name = 'Maryland';
    } else if (zipCode >= 20335 && zipCode <= 20797) {
      state.code = 'MD';
      state.name = 'Maryland';
    } else if (zipCode >= 20812 && zipCode <= 21930) {
      state.code = 'MD';
      state.name = 'Maryland';
    } else if (zipCode >= 3901 && zipCode <= 4992) {
      state.code = 'ME';
      state.name = 'Maine';
    } else if (zipCode >= 48001 && zipCode <= 49971) {
      state.code = 'MI';
      state.name = 'Michigan';
    } else if (zipCode >= 55001 && zipCode <= 56763) {
      state.code = 'MN';
      state.name = 'Minnesota';
    } else if (zipCode >= 63001 && zipCode <= 65899) {
      state.code = 'MO';
      state.name = 'kc96 DataMO';
    } else if (zipCode >= 38601 && zipCode <= 39776) {
      state.code = 'MS';
      state.name = 'Mississippi';
    } else if (zipCode >= 71233 && zipCode <= 71233) {
      state.code = 'MS';
      state.name = 'Mississippi(Warren)';
    } else if (zipCode >= 59001 && zipCode <= 59937) {
      state.code = 'MT';
      state.name = 'Montana';
    } else if (zipCode >= 27006 && zipCode <= 28909) {
      state.code = 'NC';
      state.name = 'North Carolina';
    } else if (zipCode >= 58001 && zipCode <= 58856) {
      state.code = 'ND';
      state.name = 'North Dakota';
    } else if (zipCode >= 68001 && zipCode <= 68118) {
      state.code = 'NE';
      state.name = 'Nebraska';
    } else if (zipCode >= 68122 && zipCode <= 69367) {
      state.code = 'NE';
      state.name = 'Nebraska';
    } else if (zipCode >= 3031 && zipCode <= 3897) {
      state.code = 'NH';
      state.name = 'New Hampshire';
    } else if (zipCode >= 7001 && zipCode <= 8989) {
      state.code = 'NJ';
      state.name = 'New Jersey';
    } else if (zipCode >= 87001 && zipCode <= 88441) {
      state.code = 'NM';
      state.name = 'New Mexico';
    } else if (zipCode >= 88901 && zipCode <= 89883) {
      state.code = 'NV';
      state.name = 'Nevada';
    } else if (zipCode >= 6390 && zipCode <= 6390) {
      state.code = 'NY';
      state.name = 'New York (Fishers Is)';
    } else if (zipCode >= 10001 && zipCode <= 14975) {
      state.code = 'NY';
      state.name = 'New York';
    } else if (zipCode >= 43001 && zipCode <= 45999) {
      state.code = 'OH';
      state.name = 'Ohio';
    } else if (zipCode >= 73001 && zipCode <= 73199) {
      state.code = 'OK';
      state.name = 'Oklahoma';
    } else if (zipCode >= 73401 && zipCode <= 74966) {
      state.code = 'OK';
      state.name = 'Oklahoma';
    } else if (zipCode >= 97001 && zipCode <= 97920) {
      state.code = 'OR';
      state.name = 'Oregon';
    } else if (zipCode >= 15001 && zipCode <= 19640) {
      state.code = 'PA';
      state.name = 'Pennsylvania';
    } else if (zipCode >= 0 && zipCode <= 0) {
      state.code = 'PR';
      state.name = 'Puerto Rico';
    } else if (zipCode >= 2801 && zipCode <= 2940) {
      state.code = 'RI';
      state.name = 'Rhode Island';
    } else if (zipCode >= 29001 && zipCode <= 29948) {
      state.code = 'SC';
      state.name = 'South Carolina';
    } else if (zipCode >= 57001 && zipCode <= 57799) {
      state.code = 'SD';
      state.name = 'South Dakota';
    } else if (zipCode >= 37010 && zipCode <= 38589) {
      state.code = 'TN';
      state.name = 'Tennessee';
    } else if (zipCode >= 73301 && zipCode <= 73301) {
      state.code = 'TX';
      state.name = 'Texas (Austin)';
    } else if (zipCode >= 75001 && zipCode <= 75501) {
      state.code = 'TX';
      state.name = 'Texas';
    } else if (zipCode >= 75503 && zipCode <= 79999) {
      state.code = 'TX';
      state.name = 'Texas';
    } else if (zipCode >= 88510 && zipCode <= 88589) {
      state.code = 'TX';
      state.name = 'Texas (El Paso)';
    } else if (zipCode >= 84001 && zipCode <= 84784) {
      state.code = 'UT';
      state.name = 'Utah';
    } else if (zipCode >= 20040 && zipCode <= 20041) {
      state.code = 'VA';
      state.name = 'Virginia';
    } else if (zipCode >= 20040 && zipCode <= 20167) {
      state.code = 'VA';
      state.name = 'Virginia';
    } else if (zipCode >= 20042 && zipCode <= 20042) {
      state.code = 'VA';
      state.name = 'Virginia';
    } else if (zipCode >= 22001 && zipCode <= 24658) {
      state.code = 'VA';
      state.name = 'Virginia';
    } else if (zipCode >= 5001 && zipCode <= 5495) {
      state.code = 'VT';
      state.name = 'Vermont';
    } else if (zipCode >= 5601 && zipCode <= 5907) {
      state.code = 'VT';
      state.name = 'Vermont';
    } else if (zipCode >= 98001 && zipCode <= 99403) {
      state.code = 'WA';
      state.name = 'Washington';
    } else if (zipCode >= 53001 && zipCode <= 54990) {
      state.code = 'WI';
      state.name = 'Wisconsin';
    } else if (zipCode >= 24701 && zipCode <= 26886) {
      state.code = 'WV';
      state.name = 'West Virginia';
    } else if (zipCode >= 82001 && zipCode <= 83128) {
      state.code = 'WY';
      state.name = 'Wyoming';
    }

    return state[type];
  }

  getVehicleImage(request: any): Observable<any> {
    return this.http.get<any>(this._fuel_api, { params: <any>request }).pipe(
      map((response) => {
        if (response.Error) {
          throw new HttpErrorResponse({ error: response.Error, status: 404, statusText: 'Not Found' });
        }
        //explicitly map result
        return {
          Image: response.Image,
        };
      }),
      catchError((err) => {
        //broadcast error, if anyone listening
        this.errorReportingService.next(err);
        //re-throw error so the caller can handle it
        return throwError(err);
      })
    );
  }

  get_local_dealers(postalCode: string): Observable<DGLDealerItem[]> {
    const _url = `${this._baseURL}dealer-locator/search/`;
    return this.http.get<any>(`${_url}`, { params: { postalCode: postalCode } }).pipe(
      map((response: any) => {
        return response.Data ?? [];
      })
      /*catchError(err => {
        //this.broadcastService.broadcast(EVENTS.ERROR_EVENT, err);
        return of(null);
      })*/
    );
  }

  get_colors_exterior(uvc: string, language?: string): Promise<ColorItem[]> {
    const _url = `${this._baseURL}used-cars/color/exterior/${uvc}`;
    return this.http
      .get<any>(`${_url}`, { params: Utils.kvpStripNulls({ language: language }) })
      .pipe(
        map((response: any) => {
          return response.Data.colors;
        })
      )
      .toPromise();
  }
}
