import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Message } from 'src/types/message';
import { MessageData } from 'src/types/MessageData';
import {
  ConditionQuestionAnswer,
  TradeValueFlow,
  Lead,
  Option,
  SelectedVehicle,
  TradeValueRequestType,
  Consumer,
  NextVehicle,
  Contact,
} from 'src/types/vehicle';
import { ClientDetails, VehicleSearchTypeEnum } from '../models/client-details';
import { ConditionQuestionsResponse, UserAnswer } from 'src/types/condition-question';
import { Step } from 'src/types/step';
import { GuaranteedValue } from 'src/types/guaranteed-value';
import { ReviseLeadInfo } from 'src/types/lead-info';
import { ConsumerAnswers } from 'src/types/TraderLead';
import { HttpErrorResponse } from '@angular/common/http';
import { TraderTradeValue } from 'src/types/TraderTradeValue';
import { EstimatedTradeValue } from 'src/types/EstimatedTradeValue';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  constructor() {
    //console.log("BroadcastService constructor");
  }

  private _handler: Subject<Message> = new Subject<Message>();

  broadcast(type: EVENTS, payload: any = null) {
    this._handler.next({ type, payload });
  }

  subscribe(type: EVENTS, callback: (payload: any) => void): Subscription {
    return this._handler
      .pipe(filter((message) => message.type === type))
      .pipe(map((message) => message.payload))
      .subscribe(callback);
  }

  appLoadedEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  emitAppLoadedEvent() {
    this.appLoadedEvent.next(true);
  }

  public stepEvent: BehaviorSubject<Step | null> = new BehaviorSubject<Step | null>(null);
  emitStepEvent(step: Step) {
    this.stepEvent.next(step);
  }

  public forwardStepEvent: Subject<void> = new Subject<void>();
  emitForwardStepEvent() {
    //delay execution to make sure step event is fired last
    setTimeout(() => {
      this.forwardStepEvent.next();
    }, 0);
  }

  public backStepEvent: Subject<void> = new Subject<void>();
  emitBackStepEvent() {
    this.backStepEvent.next();
  }

  public selectedVehicle: BehaviorSubject<SelectedVehicle | undefined> = new BehaviorSubject<
    SelectedVehicle | undefined
  >(undefined);
  emitSelectedVehicle(vehicle: SelectedVehicle) {
    if (vehicle !== undefined) this.selectedVehicle.next(vehicle);
  }

  public languageSubscriber: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public language: string | null = null;
  emitSelectedLanguage(language: string) {
    this.language = language;
    if (language !== undefined) this.languageSubscriber.next(language);
  }

  public vehicleOptions: BehaviorSubject<Option[] | undefined> = new BehaviorSubject<Option[] | undefined>(undefined);
  emitVehicleOptions(options: Option[]) {
    if (options !== undefined) this.vehicleOptions.next(options);
  }

  public reviseInvalidOptions: BehaviorSubject<Option[] | undefined> = new BehaviorSubject<Option[] | undefined>(
    undefined
  );
  emitReviseInvalidOptions(options: Option[]) {
    if (options !== undefined) this.reviseInvalidOptions.next(options);
  }

  public vehicleSelectedOptions: BehaviorSubject<Option[] | undefined> = new BehaviorSubject<Option[] | undefined>(
    undefined
  );
  emitVehicleSelectedOptions(options: Option[]) {
    if (options !== undefined) this.vehicleSelectedOptions.next(options);
  }

  public clientDetails: BehaviorSubject<ClientDetails | undefined> = new BehaviorSubject<ClientDetails | undefined>(
    undefined
  );
  emitClientDetails(clientDetails: ClientDetails) {
    if (clientDetails !== undefined) this.clientDetails.next(clientDetails);
  }
  // Trademax-335
  public googleRecaptcha: Subject<string | undefined> = new Subject<string | undefined>();
  emitGoogleRecaptcha(googleRecaptcha: string) {
    if (googleRecaptcha !== undefined) this.googleRecaptcha.next(googleRecaptcha);
  }

  public consumerDetails: BehaviorSubject<Consumer> = new BehaviorSubject<Consumer>(undefined);
  emitConsumerDetails(consumerDetails: Consumer) {
    if (consumerDetails !== undefined) this.consumerDetails.next(consumerDetails);
  }

  public _messageDialog: Subject<MessageData> = new Subject<MessageData>();
  showMessageDialog(msg: MessageData) {
    this._messageDialog.next(msg);
  }

  public vehicleConditionQuestions: BehaviorSubject<ConditionQuestionsResponse | null> =
    new BehaviorSubject<ConditionQuestionsResponse | null>(null);
  emitVehicleConditionQuestions(questions: ConditionQuestionsResponse) {
    this.vehicleConditionQuestions.next(questions);
  }

  public vehicleConditionAnswers: BehaviorSubject<ConditionQuestionAnswer[] | null> = new BehaviorSubject<
    ConditionQuestionAnswer[] | null
  >(null);
  emitVehicleConditionAnswers(tradeValueAnswers: ConditionQuestionAnswer[]) {
    this.vehicleConditionAnswers.next(tradeValueAnswers);
  }

  tradeValueRequestType: BehaviorSubject<TradeValueRequestType | null> =
    new BehaviorSubject<TradeValueRequestType | null>(null);
  emitTradeValueRequestType(type: TradeValueRequestType) {
    this.tradeValueRequestType.next(type);
  }

  tradeValueEST: BehaviorSubject<EstimatedTradeValue | null> = new BehaviorSubject<EstimatedTradeValue | null>(null);
  emitTradeValueEST(estValue: EstimatedTradeValue) {
    this.tradeValueEST.next(estValue);
  }

  tradeValueGTV: BehaviorSubject<GuaranteedValue | null> = new BehaviorSubject<GuaranteedValue | null>(null);
  emitTradeValueGTV(guaranteedValue: GuaranteedValue) {
    this.tradeValueGTV.next(guaranteedValue);
  }

  tradeValueTrader: BehaviorSubject<TraderTradeValue | null> = new BehaviorSubject<TraderTradeValue | null>(null);
  emitTraderTradeValue(tradeValue: TraderTradeValue) {
    this.tradeValueTrader.next(tradeValue);
  }

  public phoneValidated: Subject<void> = new Subject<void>();
  emitPhoneValidatedEvent() {
    this.phoneValidated.next();
  }

  public leadReady: Subject<Lead> = new Subject<Lead>();
  emitLeadReadyEvent(lead: Lead | any) {
    this.leadReady.next(lead);
  }

  public reviseLeadInfo: BehaviorSubject<ReviseLeadInfo | null> = new BehaviorSubject<ReviseLeadInfo | null>(null);
  emitReviseLeadInfo(info: ReviseLeadInfo) {
    this.reviseLeadInfo.next(info);
  }

  emitErrorEvent(e: Error) {
    this.broadcast(EVENTS.ERROR_EVENT, e);
  }

  tradevalueFlow: BehaviorSubject<TradeValueFlow | null> = new BehaviorSubject<TradeValueFlow | null>(null);
  emitTradeValueFlow(flow: TradeValueFlow) {
    this.tradevalueFlow.next(flow);
  }

  provider_program_id: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  emitProviderProgramId(provider_program_id: string) {
    this.provider_program_id.next(provider_program_id);
  }

  authorization: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    localStorage.getItem('authorization')
  );
  emitAuthorization(authorization: string) {
    if (!authorization) {
      localStorage.removeItem('authorization');
    } else {
      localStorage.setItem('authorization', authorization);
    }
    this.authorization.next(authorization);
  }

  public contactInfo: BehaviorSubject<Contact | null> = new BehaviorSubject<Contact | null>(null);
  emitContactInfo(contact: Contact) {
    this.contactInfo.next(contact);
  }

  public nextVehicleInfo: BehaviorSubject<NextVehicle | null> = new BehaviorSubject<NextVehicle | null>(null);
  emitNexVehicleInfo(next_vehicle: NextVehicle) {
    this.nextVehicleInfo.next(next_vehicle);
  }

  public searchType: BehaviorSubject<VehicleSearchTypeEnum | undefined> = new BehaviorSubject<
    VehicleSearchTypeEnum | undefined
  >(undefined);
  emitSearchType(search_type: VehicleSearchTypeEnum) {
    this.searchType.next(search_type);
  }

  // Notify when a HTTP Request is running and when it ends
  private requestCount = 0;
  public requestStatus = new BehaviorSubject<boolean>(false);
  emitRequestEnd() {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.requestStatus.next(false);
    }
  }
  emitRequestStart() {
    this.requestCount++;
    this.requestStatus.next(true);
  }

  // Notify the children components to validate their form
  validateEvent: Subject<void> = new Subject<void>();
  emitValidateEvent() {
    this.validateEvent.next();
  }

  customQuestionsAnswers = new BehaviorSubject<UserAnswer | null>(null);
  emitCustomQuestionsAnswers(answers: UserAnswer) {
    this.customQuestionsAnswers.next(answers);
  }

  consumerQuestionsAnswers = new BehaviorSubject<ConsumerAnswers | null>(null);
  emitConsumerQuestionsAnswers(consumerQuestionsAnswers: ConsumerAnswers) {
    this.consumerQuestionsAnswers.next(consumerQuestionsAnswers);
  }

  tradeValueError = new Subject<HttpErrorResponse | null>();
  emitTradeValueError(error: HttpErrorResponse) {
    this.tradeValueError.next(error);
  }
}

export enum EVENTS {
  EVENT,
  ERROR_EVENT,
  LEAD_RESPONSE,
}
